.App-home-title {
    color: #900C3F;
}

.App-home-image {
    width: 100%;
    height: 100%;
    margin-bottom: 1px;
}

.App-home-title figcaption {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    /*font-style: italic;*/
    font-family: Verdana, serif;
    padding: 3px;
    text-align: left;
    font-size: 10px;
}

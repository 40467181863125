.App-header {
    /*width: 411px;*/
}

.App-header-name {
    font-family: Courier New, sans-serif;
    font-weight: revert;
    font-size: 3.9em;
    /*font-size: 63px;*/
    /*font-size: 2.8vw;*/
    letter-spacing: 0.04rem;
    /*color: #900C3F;*
    color: #202020;
    /*color: darkgrey;*/
    opacity: 0.04;
}

.App-header-nav {
    font-family: sans-serif;
    font-size: 11px;
}

.App-header-nav:link, .App-header-nav:visited {
    /*width: 52px;*/
    border-radius: 1px;
    margin: 1px;
    background-color: #202020;
    color: white;
    /*padding: 14px 25px;*/
    padding: 6px 6px 6px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

.App-header-nav:hover {
    background-color: #900C3F;
    color: ghostwhite;
}

.App-header-nav:active {
    background-color: #900C3F;
    color: ghostwhite;
}



.App-header-nav-stayActive {
    font-family: sans-serif;
    font-size: 11px;

    border-radius: 1px;
    margin: 1px;
    background-color: #900C3F;
    color: ghostwhite;
    /*padding: 14px 25px;*/
    padding: 6px 6px 6px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

.App-header-nav-stayActive:hover {
    background-color: #900C3F;
    color: ghostwhite;
}

.App-header-nav-stayActive:active {
    background-color: #900C3F;
    color: ghostwhite;
}
.App-sketch-title {
    color: #900C3F;
}

.App-sketch-image {
    width: 100%;
    height: 100%;
    margin-bottom: 1px;
}

.App-sketch-figcation {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    /*font-style: italic;*/
    font-family: Verdana, serif;
    padding: 3px;
    text-align: left;
    font-size: 10px;
}

.App-sketch-circle {
    margin: 6px;
    width: 8px;
    height: 8px;
    /*-webkit-border-radius: 2px;*/
    /*-moz-border-radius: 2px;*/
    border-radius: 8px;
    /*background: black;*/
}
.App-essay-name {
    color: #900C3F;
}

.App-essay-title {
    color: #900C3F;
    font-family:  "Trebuchet MS", "Microsoft YaHei", sans-serif;
    font-size: 16px;
}

.App-essay-articles {
    font-family: "Trebuchet MS", "Microsoft YaHei", sans-serif;
    font-size: small;
}

.App-essay-article {
    font-family: "Trebuchet MS", "Microsoft YaHei", sans-serif;
    font-size: 14px;

    /* 确保代码块不会超出容器宽度 */
    code, pre {
        white-space: pre-wrap;  /* 自动换行 */
        word-wrap: break-word;  /* 防止长单词超出容器 */
        overflow-x: auto;       /* 如果宽度超出时可以水平滚动 */
        overflow-y: hidden;     /* 隐藏竖向滚动条 */
        max-width: 100%;        /* 最大宽度为容器的宽度 */
        background: white;
    }
}

.App-essay-links {
    font-family: monospace;
    color: black;
}

.App-essay-links:hover {
    font-family: monospace;
    color: darkred;
}

.App-essay-links:visited {
    font-family: monospace;
    color: dimgray;
}

code {
    background-color: #eee;
    border-radius: 3px;
    font-family: courier, monospace;
    padding: 3px 3px;
}
@import "App.css";
@import "./styles/Header.css";
@import "./styles/Content.css";
@import "./styles/Footer.css";
@import "./styles/Essay.css";
@import "./styles/Sketch.css";
@import "./styles/Research.css";
@import "./styles/Contact.css";
@import "./styles/Home.css";
@import "styles/Thoughts.css";
@import "./styles/Web3.css";
@import "./styles/Game.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

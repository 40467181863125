.App-footer {
    /*width: 411px;*/
}

.App-footer-copyright {
    color: lightgrey;
    font-size: 10px;
    border-bottom: solid 1px;
    padding-bottom: 0.6rem;
    /*padding: 6px;*/
}
.App-web3-title {
    color: #900C3F;
}

.App-web3-paragraph {
    font-family: monospace;
    font-size: small;
}

.App-webs-gif {
    width: 336px;
}
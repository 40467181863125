.App-game-title {
    color: #900C3F;
}

.App-game-image {
    width: 236px;
    /*margin-left: 20%;*/
}

.App-game-paragraph {
    font-family: monospace;
    font-size: small;
}

.App-game-illustration {
    /*width: 100%;*/
    /*height: 100%;*/
    width: 386px;
    background-color: black;
    color: white;
    /*font-style: italic;*/
    font-family: Verdana, serif;
    padding: 6px;
    text-align: left;
    font-size: 11px;
}
